import { default as breakOJBvM8k3xNMeta } from "/workspace/pages/break.vue?macro=true";
import { default as charts70XSZeHFL8Meta } from "/workspace/pages/charts.vue?macro=true";
import { default as ControlSearchbAQ0eUeEQQMeta } from "/workspace/pages/ControlSearch.vue?macro=true";
import { default as cpP8JUwXGrJSMeta } from "/workspace/pages/cp.vue?macro=true";
import { default as edit_controljoRjQAnYrMMeta } from "/workspace/pages/edit_control.vue?macro=true";
import { default as form_inithHIAb4Oeu0Meta } from "/workspace/pages/form_init.ts?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as SearchComboboxI7UUb8S4XpMeta } from "/workspace/pages/SearchCombobox.vue?macro=true";
import { default as seriegXj9yvZbaTMeta } from "/workspace/pages/serie.vue?macro=true";
export default [
  {
    name: "break",
    path: "/break",
    component: () => import("/workspace/pages/break.vue")
  },
  {
    name: "charts",
    path: "/charts",
    component: () => import("/workspace/pages/charts.vue")
  },
  {
    name: "ControlSearch",
    path: "/ControlSearch",
    component: () => import("/workspace/pages/ControlSearch.vue")
  },
  {
    name: "cp",
    path: "/cp",
    component: () => import("/workspace/pages/cp.vue")
  },
  {
    name: "edit_control",
    path: "/edit_control",
    component: () => import("/workspace/pages/edit_control.vue")
  },
  {
    name: "form_init",
    path: "/form_init",
    component: () => import("/workspace/pages/form_init.ts")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/workspace/pages/login.vue")
  },
  {
    name: "SearchCombobox",
    path: "/SearchCombobox",
    component: () => import("/workspace/pages/SearchCombobox.vue")
  },
  {
    name: "serie",
    path: "/serie",
    component: () => import("/workspace/pages/serie.vue")
  }
]