import type { Me } from "~/types";
import * as api from "~/services/api";

const me = ref<Me>();

export function getMe() {
  if (!me.value) {
    throw new Error("User not defined");
  }

  return me.value;
}

export async function setUser() {
  try {
    const user = await api.me();
    me.value = user;
    return user;
  } catch (e) {
    return null;
  }
}

export { me };
