import * as api from "~/services/api";
import * as ls from "~/services/localstorage";
import * as auth from "~/services/auth";

export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig();
  auth.config(config.public.HOST);

  const authToken = await ls.token.load();

  if (authToken) {
    api.authenticate(authToken.access, config.public.HOST);
    auth.authenticate(authToken.access);

    let appConfig = await ls.config.load();
    if (!appConfig) {
      const applications = await api.applications();
      appConfig = { application: applications[0] };
      ls.config.dump(appConfig);
    } else if (
      typeof to.params.slug === "string" &&
      appConfig.application.slug !== to.params.slug
    ) {
      const applications = await api.applications();
      const currentApplication = applications.find(
        (app) => app.slug === to.params.slug
      );

      if (currentApplication) {
        ls.config.dump({ application: currentApplication });
      } else {
        ls.config.dump({ application: applications[0] });
      }
    }

    if (to.name === "login" || to.name === "start") {
      return navigateTo(
        {
          name: "home",
          params: {
            slug: appConfig.application.slug,
          },
        },
        { replace: true }
      );
    }
  } else if (to.name !== "login") {
    return navigateTo({ name: "login" }, { replace: true });
  }
});
