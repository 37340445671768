import * as ls from "~/services/localstorage";
import { setUser } from "~/store";
import type { Permissions } from "~/types";

export default defineNuxtRouteMiddleware(async (to) => {
  let appConfig = await ls.config.load();
  const pagePermissions = (to.meta.permissions as (keyof Permissions)[]) || [];
  appConfig = await ls.config.load();
  const user = await setUser();

  if (pagePermissions.length === 0) {
    return;
  }

  let canAccess = false;
  if (to.name !== "login") {
    if (appConfig && user) {
      const slug = appConfig.application.slug;
      const userPermissions = user.applications[slug];
      pagePermissions.forEach((permission) => {
        canAccess = canAccess || userPermissions.permissions[permission];
      });

      if (!canAccess) {
        return navigateTo(
          {
            name: "home",
            params: {
              slug: appConfig.application.slug,
              controlId: to.params.controlId,
            },
          },
          { replace: true }
        );
      }
    }
  }
});
