import type { Application } from "~/types";

async function load<T>(key: string): Promise<T | null> {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  }

  return null;
}

function dump(key: string, data: object) {
  localStorage.setItem(key, JSON.stringify(data));
}

type Token = {
  access: string;
  refresh: string;
};

export const token = {
  load: () => load<Token>("auth"),
  dump: (item: Token) => dump("auth", item),
  delete: () => localStorage.removeItem("auth"),
};

type Config = {
  application: Application;
};

export const config = {
  load: () => load<Config>("config"),
  dump: (item: Config) => dump("config", item),
};
